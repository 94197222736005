import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconCard from '../../../../components/SavedCardDetails/IconCard/IconCard';
import './sliderMenu.css';
import { AvatarLarge, InlineTextButton, NamedLink } from '../../../../components';
import css from './SliderMobileMenu.module.css';
import { categories, collections } from '../../../../util/constants';
import { handleRedirectTosearch } from '../TopbarDesktop/CategoriesDropdownMenu';
import { FormattedMessage } from '../../../../util/reactIntl';
import { useHistory } from 'react-router-dom';
import SearchField from './SearchField';

const AccordionComponent = ({ title, content, extraContent = [] }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {extraContent}
        {content}
      </AccordionDetails>
    </Accordion>
  );
};

const NestedSearchButtons = ({ currentUser, redirect }) => {
  return (
    <AccordionComponent
      title={'Shop now'}
      content={[...categories, collections].map(c => {
        if (c.type === 'Collections') {
          return (
            <AccordionComponent
              title={'Edits'}
              content={c.data[0].links.map(link => {
                return (
                  <p>
                    <a
                      className={`${css.link} ${css.smallerSizeText}`}
                      onClick={() =>
                        handleRedirectTosearch({
                          link,
                          currentUser,
                          redirect,
                          title: c.type,
                          type: c.type,
                        })
                      }
                    >
                      {link}
                    </a>
                  </p>
                );
              })}
            />
          );
        }
        if (c.type === 'Brands') {
          return (
            <AccordionComponent
              title={c.type}
              content={c.data[0].links
                .filter(l =>
                  [
                    'Bonpoint',
                    'Burberry',
                    'Fendi',
                    'Gucci',
                    'Moncler',
                    'Ralph Lauren',
                    'All Brands',
                  ].includes(l)
                )
                .map(link => {
                  return (
                    <p>
                      <a
                        className={`${css.link} ${css.smallerSizeText}`}
                        onClick={() =>
                          handleRedirectTosearch({
                            link,
                            currentUser,
                            redirect,
                            title: c.type,
                            type: c.type,
                          })
                        }
                      >
                        {link}
                      </a>
                    </p>
                  );
                })}
            />
          );
        }
        return (
          <AccordionComponent
            title={c.type}
            content={c.data
              .filter(sc => sc.title && sc.title !== 'Collections')
              .map(sc => {
                return (
                  <AccordionComponent
                    title={sc.title}
                    content={sc.links.map(link => {
                      return (
                        <p>
                          <a
                            className={`${css.link} ${css.smallerSizeText}`}
                            onClick={() =>
                              handleRedirectTosearch({
                                link,
                                currentUser,
                                redirect,
                                title: sc.title,
                                type: c.type,
                              })
                            }
                          >
                            {link}
                          </a>
                        </p>
                      );
                    })}
                  />
                );
              })}
          />
        );
      })}
      extraContent={[
        <>
          <br />
          <NamedLink className={css.link} name="AlgoliaSearchPage">
            New In
          </NamedLink>
        </>,
      ]}
    />
  );
};

const SliderMobileMenu = ({
  isAuthenticated,
  currentUser,
  redirect,
  onLogout,
  notificationCount,
}) => {
  const history = useHistory();
  if (isAuthenticated) {
    const displayName = currentUser?.attributes?.profile?.firstName;

    return (
      <Menu
        customBurgerIcon={<IconCard brand={'sideLine'} />}
        customCrossIcon={<CloseIcon className={css.closeButton} />}
      >
        <SearchField />
        <AvatarLarge className={css.avatar} user={currentUser} />
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <NamedLink className={css.link} name="CartPage">
          <IconCard brand={'cart'} key={'cart-icon'} />{' '}
          <FormattedMessage id="TopbarMobileMenu.CartPageLink" />
        </NamedLink>

        <NamedLink className={css.link} name="ManageListingsPage">
          <FormattedMessage id="TopbarDesktop.yourListingsLink" />
        </NamedLink>

        <NamedLink className={css.link} name="InboxBasePage">
          <FormattedMessage
            id="TopbarDesktop.InboxLinkText"
            values={{ count: notificationCount || 0 }}
          />
        </NamedLink>
        <NamedLink className={css.link} name="ProfileSettingsPage">
          <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
        </NamedLink>
        <NestedSearchButtons currentUser={currentUser} redirect={redirect} />
        <div className={css.extraLinksSection}>
          <span className={css.link} onClick={() => history.push('/p/how-it-works-lw2')}>
            How It Works
          </span>
          <span className={css.link} onClick={() => history.push('/p/faq')}>
            FAQs
          </span>
        </div>
        <div className={css.divider}></div>

        <div className={css.cartLinkWrapper}>
          {/* <NamedLink className={css.cartLink} name="CartPage">
            <IconCard brand={'cartphone'} key={'cart-icon'} />
            <FormattedMessage id="TopbarMobileMenu.CartPageLink" />
          </NamedLink> */}
          <NamedLink className={css.cartLink} name="NewListingPage">
            Sell an item
          </NamedLink>
        </div>
      </Menu>
    );
  }

  return (
    <Menu
      customBurgerIcon={<IconCard brand={'sideLine'} />}
      customCrossIcon={<CloseIcon className={css.closeButton} />}
    >
      <SearchField />

      <NestedSearchButtons currentUser={currentUser} redirect={redirect} />

      <NamedLink className={css.link} name="NewListingPage">
        Sell now
      </NamedLink>
      <NamedLink className={css.link} name="LoginPage">
        Log in
      </NamedLink>
      <NamedLink className={css.link} name="SignupPage">
        Sign up
      </NamedLink>
      <div className={css.extraLinksSection}>
        <span className={css.link} onClick={() => history.push('/p/how-it-works-lw2')}>
          How It Works
        </span>
        <span className={css.link} onClick={() => history.push('/p/faq')}>
          FAQs
        </span>
      </div>
      <div className={css.divider}></div>
      <div className={css.cartLinkWrapper}>
        <NamedLink className={css.cartLink} name="NewListingPage">
          Sell an item
        </NamedLink>
      </div>
    </Menu>
  );
};

export default SliderMobileMenu;
