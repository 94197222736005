import React, { useState } from 'react';
import css from './SearchField.module.css';
import { useHistory } from 'react-router-dom';
import IconCard from '../../../../components/SavedCardDetails/IconCard/IconCard';

const SearchField = () => {
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  return (
    <div className={css.wrapper}>
      <IconCard className={css.searchIcon} brand={'search'} key={'search-icon'} />
      <input
        className={css.searchInput}
        onChange={e => setSearchText(e.target.value)}
        value={searchText}
        onKeyDown={e => e.key === 'Enter' && history.push(`/search?query=${encodeURI(searchText)}`)}
        placeholder="Search..."
      />
    </div>
  );
};

export default SearchField;
